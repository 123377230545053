<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form
          ref="formName"
          @submit.prevent
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <validation-observer ref="simpleRules">
              <b-overlay
                :show="invoiceData.showOverLay "
                rounded="sm"
                :opacity="0.5"
              >
                <inv-header
                  v-if="isLoadEnd"
                  :invoice-data="invoiceData"
                />
                <inv-item
                  v-if="isLoadEnd"
                  :invoice-data="invoiceData"
                />
                <inv-footer
                  v-if="isLoadEnd"
                  :invoice-data="invoiceData"
                />
                <b-col cols="6">
                  <!-- alert -->
                  <b-alert
                    v-height-fade.appear
                    variant="danger"
                    :show="invoiceData.showError"
                    class="text-left p-1"
                  >
                    <ul class="list-style-icons mb-0">
                      <li><feather-icon icon="ArrowRightIcon" />{{ invoiceData.apiErrors }}</li>
                    </ul>
                  </b-alert>
                </b-col>
                <b-col cols="3" />
              </b-overlay>
            </validation-observer>
          </b-card>
        </b-form>
      </b-col>
      <inv-right
        v-if="isLoadEnd"
        :invoice-data="invoiceData"
        @submit-form="validationForm"
      />
      <invoice-sidebar-send-invoice />
      <invoice-sidebar-add-payment
        v-if="isLoadEnd"
        :invoice-data="invoiceData"
      />
    </b-row>
  </section>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BCard, BForm, BOverlay, BAlert,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import invHeader from '@/views/components/invoice/inv-header.vue'
import invItem from '@/views/components/invoice/inv-item.vue'
import invFooter from '@/views/components/invoice/inv-footer.vue'
import invRight from '@/views/components/invoice/inv-right.vue'
import purchaseInvoice from '@/service/purchase/purchase-invoice'
import status from '@/service/administration/status'
import document from '@/service/administration/document'
import supplier from '@/service/crm/supplier'
import bankBranch from '@/service/administration/bank-branch'
import { ref } from '@vue/composition-api'
import InvoiceSidebarSendInvoice from '@/views/apps/invoice/InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '@/views/apps/invoice/InvoiceSidebarAddPayment.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
    invHeader,
    invItem,
    invFooter,
    invRight,
    BRow,
    BCol,
    BCard,
    BAlert,
    BForm,
    BOverlay,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      user: '',
      one_bank_branch: '',
      isLoadEnd: false,
    }
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem('userData'))
    this.loadLabel()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.putApi()
        }
        this.switchLoc()
      })
    },
    async putApi() {
      this.invoiceData.showOverLay = true
      const formData = new FormData()
      formData.append('date', this.invoiceData.master.date)
      formData.append('due_date', this.invoiceData.master.due_date)
      formData.append('organization_branch_id', this.invoiceData.master.organization_branch_id)
      formData.append('warehouse_id', this.invoiceData.master.warehouse_id.id)
      formData.append('financial_year_id', this.invoiceData.master.financial_year_id ? this.invoiceData.master.financial_year_id.id : 0)
      formData.append('vendor_set_id', this.invoiceData.client.id)
      formData.append('total_price', this.invoiceData.master.total_price)
      formData.append('document_type_id', this.invoiceData.document ? this.invoiceData.document.id : 0)
      formData.append('document', JSON.stringify(this.invoiceData.document))
      formData.append('based_document_id', this.invoiceData.master.based_document_id === null ? '' : this.invoiceData.master.based_document_id)
      formData.append('based_document_type_id', this.invoiceData.master.based_document_type_id.id ? this.invoiceData.master.based_document_type_id.id : '')
      formData.append('based_document_no', this.invoiceData.master.based_document_no === null ? '' : this.invoiceData.master.based_document_no)
      formData.append('description', this.invoiceData.master.description)
      formData.append('suspended', this.invoiceData.master.suspended ? 1 : 0)
      formData.append('status_id', this.invoiceData.master.status_id.id ? this.invoiceData.master.status_id.id : '')
      formData.append('user_id', this.user.id)
      formData.append('items', JSON.stringify(this.invoiceData.items))
      // eslint-disable-next-line no-plusplus
      for (let i = 0, l = this.invoiceData.file.length; i < l; i++) {
        formData.append(`file[${i}]`, this.invoiceData.file[i])
      }
      await purchaseInvoice.putAxios(this.invoiceData.master.id, formData).then(() => {
        this.invoiceData.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.invoiceData.showOverLay = false
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    loadLabel() {
      this.invoiceData.master.id = router.currentRoute.params.id
      this.invoiceData.documentName = this.$t('Purchases Invoice')
      this.invoiceData.to = this.$t('InvoiceInfo.Invoice To')
      this.invoiceData.clientOrVenderName = this.$t('Supplier')
      this.invoiceData.saveUpdateText = this.$t('General.Update')
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
      this.getLoadUpdateData()
    },
    async getLoadUpdateData() {
      this.isLoadEnd = false
      if (this.invoiceData.master.id) {
        this.invoiceData.showOverLay = true
        let isresponse = false
        await purchaseInvoice.oneAxios(this.invoiceData.master.id).then(response => {
          if (response.data) {
            isresponse = true
            this.invoiceData.master = response.data
            this.invoiceData.master.warehouse_id = response.data.warehouse
            this.invoiceData.items = response.data.purchase_invoice_details
          } else isresponse = false
          this.invoiceData.showError = false
        }).catch(error => {
          this.invoiceData.apiErrors = error
          this.invoiceData.showError = true
        })
        if (isresponse) {
          await supplier.oneAxios(this.invoiceData.master.vendor_set_id).then(response => {
            this.invoiceData.client = response.data
          }).catch(error => {
            this.invoiceData.apiErrors = error
            this.invoiceData.showError = true
          })
          await status.oneAxios(this.invoiceData.master.status_id).then(response => {
            this.invoiceData.master.status_id = response.data
          }).catch(error => {
            this.invoiceData.apiErrors = error
            this.invoiceData.showError = true
          })
          await document.oneAxios(this.invoiceData.master.based_document_type_id).then(response => {
            this.invoiceData.master.based_document_type_id = response.data
          }).catch(error => {
            this.invoiceData.apiErrors = error
            this.invoiceData.showError = true
          }).finally(() => {
            this.isLoadEnd = true
            this.invoiceData.showOverLay = false
          })
          await bankBranch.oneAxios(this.invoiceData.client.bank_branch_id).then(response => {
            setTimeout(() => {
              this.one_bank_branch = { ...response.data }
              this.invoiceData.client.bank_branch = { ...this.one_bank_branch }
            }, 1000)
          }).catch(error => {
            this.invoiceData.apiErrors = error
            this.invoiceData.showError = true
          })
        } else this.invoiceData.showOverLay = false
      }
    },
  },
  setup() {
    const invoiceData = ref({
      master: {
        id: 0,
        date: '',
        due_date: '',
        description: '',
        based_document_id: '',
        based_document_type_id: '',
        based_document_no: '',
        organization_branch_id: '',
        document_no: '',
        total_price: 0,
        warehouse_id: '',
        financial_year_id: 0,
        status_id: '',
        suspended: false,
      },
      clientOrVenderId: 1,
      document_id: 11,
      document: '',
      clientOrVenderName: 'Supplier',
      client: null,
      items: [],
      file: [],
      balance: 0,
      isEdit: true,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      documentName: 'Invoice',
      routEdit: 'purchase-updatePurchasesInvoice',
      routePerview: 'purchase-previewInvoice',
      permissionEdit: 'PurchasesInvoice',
      to: 'Invoice To',
      tableItems: 'purchase_invoice_details',
      send: true,
      preview: true,
      reset: false,
      save: true,
      edit: false,
      payment: false,
      print: true,
      download: true,
      saveUpdateText: 'Update',
    })
    const itemFormBlankItem = {
      item: null,
      item_id: 0,
      price: 0,
      quantity: 1,
      measurement_unit: null,
      measurement_unit_id: 0,
    }
    return {
      invoiceData,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
